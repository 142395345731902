<template>
  <div>
    <b-card>
      <h3 class="text-center mb-0">{{ form.work_number }} | {{ title }}</h3>
    </b-card>
    <b-modal id="document_generator_modal" ref="document_generator_modal" hide-footer title="Dokumentum generálás">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Dokumentum típusa"
            label-for="type"
          >
            <b-form-select
              v-model="type"
              :options="typeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-show="type === 'contract_attachment'">
        <b-col md="12">
          <b-form-group
            label="Monolit cég"
            label-for="monolit"
          >
            <b-form-select
              v-model="form5.monolit"
              :options="monolitOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Alvállalkozó"
            label-for="subcontractor"
          >
            <b-form-select
              v-model="form5.subcontractor"
              @change="changeForm5Subcontractor"
              :options="subcontractorOptions"
              text-field="company_name"
              value-field="id"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés kelte"
            label-for="date_of_contract"
          >
            <b-form-input
              v-model="form5.date_of_contract"
              type="date" max="9999-12-31"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="X. számú szerződésmelléklet"
            label-for="number"
          >
            <b-form-input
              v-model="form5.number"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés tárgya"
            label-for="subject_of_the_contract"
          >
            <b-form-textarea
              v-model="form5.subject_of_the_contract"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <p class="d-flex">
            <b-form-checkbox v-model="paydesc_enabled" type="checkbox"/> Árváltozás rögzítése
          </p>
        </b-col>
        <b-col md="12" class="mt-1" v-show="paydesc_enabled">
          <b-form-group
            label="Ár meghatározása"
            label-for="paydesc"
          >
            <b-form-textarea
              v-model="form5.paydesc"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Aláíró"
            label-for="signature"
          >
            <b-form-select
              v-model="form5.signature"
              :options="signatureOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <p v-if="editing" class="float-right"><button @click="editGenerateContractAttachment()" class="btn btn-primary">Szerkesztés</button></p>
          <p v-else class="float-right"><button @click="generateContractAttachment()" class="btn btn-primary">Generálás</button></p>
        </b-col>
      </b-row>
      <b-row v-show="type === 'certificate_of_completion'">
        <b-col md="12">
          <b-form-group
            label="Monolit cég"
            label-for="monolit"
          >
            <b-form-select
              disabled
              v-model="form4.monolit"
              :options="monolitOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Alvállalkozó"
            label-for="subcontractor"
          >
            <b-form-select
              v-model="form4.subcontractor"
              @change="changeForm4Subcontractor"
              :options="subcontractorOptions"
              text-field="company_name"
              value-field="id"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Teljesítés dátuma"
            label-for="tig_date"
          >
            <b-form-input
              v-model="form4.tig_date"
              type="date" max="9999-12-31"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Teljesítés száma (végszámla -1)"
            label-for="tig_number"
            class="mb-0"
          >
            <b-form-input
              v-model="form4.tig_number"
              type="number"
            />
          </b-form-group>
        </b-col>
        <p class="col-md-12"><small style="cursor:pointer" class="text-primary" @click="form4.tig_number = -1">Végszáma kiállítása</small></p>
        <b-col md="12">
          <b-form-group
            label="Munkaszám"
            label-for="work_number"
          >
            <b-form-input
              v-model="form4.work_number"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés tárgya"
            label-for="subject_of_the_contract"
          >
            <b-form-textarea
              v-model="form4.subject_of_the_contract"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Teljesítés nettó értéke"
            label-for="price"
          >
            <b-form-input
              v-model="form4.price"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Áfa mértéke"
            label-for="tax"
          >
            <b-form-select
              v-model="form4.tax"
              :options="fadOptions"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Aláíró"
            label-for="signature"
          >
            <b-form-select
              v-model="form4.signature"
              :options="signatureOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <p v-if="editing" class="float-right"><button @click="editGenerateTIG()" class="btn btn-primary">Szerkesztés</button></p>
          <p v-else class="float-right"><button @click="generateTIG()" class="btn btn-primary">Generálás</button></p>
        </b-col>
      </b-row>
      <b-row v-show="type === 'building_contract'">
        <b-col md="12">
          <b-form-group
            label="Monolit cég"
            label-for="monolit"
          >
            <b-form-select
              disabled
              v-model="form3.monolit"
              :options="monolitOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Alvállalkozó"
            label-for="subcontractor"
          >
            <b-form-select
              v-model="form3.subcontractor"
              :options="subcontractorOptions"
              text-field="company_name"
              value-field="id"
              @change="changeForm3Subcontractor()"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Adózási forma"
            label-for="taxType"
          >
            <b-form-select
              v-model="form3.taxType"
              :options="taxTypeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Módosítás száma"
            label-for="change_number"
          >
            <b-form-input
              v-model="form3.change_number"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés tárgya"
            label-for="subject_of_the_contract"
          >
            <b-form-textarea
              v-model="form3.subject_of_the_contract"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Megváltozott vállalási díj"
            label-for="new_price"
          >
            <b-form-input
              v-model="form3.new_price"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Áfa mértéke"
            label-for="tax"
          >
            <b-form-select
              v-model="form3.tax"
              :options="fadOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Megváltozott befejezési határidő"
            label-for="new_end_date"
          >
            <b-form-input
              v-model="form3.new_end_date"
              type="date" max="9999-12-31"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés kelte"
            label-for="date_of_contract"
          >
            <b-form-input
              v-model="form3.date_of_contract"
              type="date" max="9999-12-31"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Aláíró"
            label-for="signature"
          >
            <b-form-select
              v-model="form3.signature"
              :options="signatureOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <p v-if="editing" class="float-right"><button @click="editGenerateBuildingContract()" class="btn btn-primary">Szerkesztés</button></p>
          <p v-else class="float-right"><button @click="generateBuildingContract()" class="btn btn-primary">Generálás</button></p>
        </b-col>
      </b-row>
      <b-row v-show="type === 'individual_tax'">
        <b-col md="12">
          <b-form-group
            label="Monolit cég"
            label-for="monolit"
          >
            <b-form-select
              disabled
              v-model="form2.monolit"
              :options="monolitOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Alvállalkozó"
            label-for="subcontractor"
          >
            <b-form-select
              v-model="form2.subcontractor"
              :options="subcontractorOptions"
              text-field="company_name"
              value-field="id"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés kelte"
            label-for="date_of_contract"
          >
            <b-form-input
              id="date_of_contract"
              v-model="form2.date_of_contract"
              type="date" max="9999-12-31"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Aláíró"
            label-for="signature"
          >
            <b-form-select
              v-model="form2.signature"
              :options="signatureOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <p v-if="editing" class="float-right"><button @click="editGenerateIndividualTax()" class="btn btn-primary">Szerkesztés</button></p>
          <p v-else class="float-right"><button @click="generateIndividualTax()" class="btn btn-primary">Generálás</button></p>
        </b-col>
      </b-row>
      <b-row v-show="type === 'contract'">
        <b-col md="12">
          <b-form-group
            label="Általány vagy Tételes szerződés"
            label-for="tax_type"
          >
            <b-form-select
              @change="changeContractType"
              v-model="form.tax_type"
              :options="tax_typeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" v-show="form.tax_type === 'Általánydíjas'">
          <b-form-group
            label="Alvállalkozói díj"
            label-for="price"
          >
            <b-form-input
              v-model="form.price"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Munkaszám"
            label-for="work_number"
          >
            <b-form-input
              v-model="form.work_number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés tárgya"
            label-for="subject_of_the_contract"
          >
            <b-form-textarea
              v-model="form.subject_of_the_contract"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Építési engedély"
            label-for="building_permit_number"
          >
            <b-form-input
              disabled
              v-model="form.building_permit_number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Áfa mértéke"
            label-for="tax"
          >
            <b-form-select
              v-model="form.tax"
              :options="fadOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Monolit cég"
            label-for="monolit"
          >
            <b-form-select
              disabled
              v-model="form.monolit"
              :options="monolitOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Alvállalkozó"
            label-for="subcontractor"
          >
            <b-form-select
              v-model="form.subcontractor"
              :options="subcontractorOptions"
              text-field="company_name"
              value-field="id"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Milyen munkát végez?"
            label-for="work"
          >
            <b-form-select
              v-model="form.work"
              :options="workOptions"
              text-field="label"
              value-field="value"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Munkaterület átadásának napja"
            label-for="site_handover_date"
          >
            <b-form-input
              type="date" max="9999-12-31"
              v-model="form.site_handover_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Vállalt befejezési határidő"
            label-for="committed_completion_date"
          >
            <b-form-input
              type="date" max="9999-12-31"
              v-model="form.committed_completion_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Fizetési határidő (X nap)"
            label-for="payment_deadline"
          >
            <b-form-input
              type="number"
              v-model="form.payment_deadline"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Szerződés kelte"
            label-for="date_of_contract"
          >
            <b-form-input
              type="date" max="9999-12-31"
              v-model="form.date_of_contract"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Aláíró"
            label-for="signature"
          >
            <b-form-select
              v-model="form.signature"
              :options="signatureOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <p v-if="editing" class="float-right"><button @click="editGenerateContract()" class="btn btn-primary">Szerkesztés</button></p>
          <p v-else class="float-right"><button @click="generateContract()" class="btn btn-primary">Generálás</button></p>
        </b-col>
      </b-row>
    </b-modal>
    <b-card-code title="">
      <div class="row mb-2">
        <div class="col-lg-6">
          <h4>Dokumentumok</h4>
        </div>
        <div class="col-lg-6 text-right">
          <button class="btn btn-primary mr-0 mr-md-2" @click="editing = false" v-b-modal.document_generator_modal>Dokumentum generálás</button>
          <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/project/list/documents/archive/'+$route.params.id)">Archivált dokumentumok</button>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'date'">
            {{ props.row.date }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'document_name'">
            <span v-if="(props.row.type === 'certificate_of_completion' && props.row.data.tig_number !== -1)">{{props.row.data.tig_number}}. teljesítési igazolás</span>
            <span v-if="(props.row.type === 'certificate_of_completion' && props.row.data.tig_number === -1)">Végszámla</span>
            <span v-if="props.row.type === 'contract'">Alvállalkozói építési vállalkozási szerződés</span>
            <span v-if="props.row.type === 'building_contract'"> {{props.row.data.change_number}}.számú szerződés módosítás</span>
            <span v-if="props.row.type === 'individual_tax'"> Kisadózó nyilatkozat</span>
            <span v-if="props.row.type === 'contract_attachment'"> Szerződés melléklet</span>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="edit(props.row.id)">
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Szerkesztés</span>
                </b-dropdown-item>
                <b-dropdown-item @click="archive(props.row.id)">
                  <feather-icon
                    icon="FolderIcon"
                    class="mr-50"
                  />
                  <span>Archíválás</span>
                </b-dropdown-item>
                <b-dropdown-item @click="downloadDocument(props.row.id)">
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Letöltés</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mutasd 1-től
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BFormTextarea, BCol, BRow, BFormCheckbox, BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BCard,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BCol,
    BRow,
    BFormCheckbox,
  },
  data() {
    return {
      title: '',
      signatureOptions: [
        { value: 'Szabó Lajos', text: 'Szabó Lajos' },
        { value: 'Kovács Ferenc Tibor', text: 'Kovács Ferenc Tibor' },
      ],
      monolitOptions: [
        { value: 'ME', text: 'Monolit Épszer' },
        { value: 'MP', text: 'Monolit Profi KFT' },
      ],
      subcontractorOptions: [],
      typeOptions: [
        { value: 'contract', text: 'Szerződés' },
        { value: 'individual_tax', text: 'Kisadózó nyilatkozat' },
        { value: 'building_contract', text: 'Szerződés módosítás' },
        { value: 'certificate_of_completion', text: 'Teljesítés igazolás' },
        { value: 'contract_attachment', text: 'Szerződés melléklet' },
      ],
      taxTypeOptions: [
        { value: 'Tételes elszámolás szerint', text: 'Tételes elszámolás szerint' },
        { value: 'Általánydíjas', text: 'Általánydíjas' },
      ],
      type: 'Szerződés',
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Szerződés típusa',
          field: 'document_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés munkaszám',
          },
        },
        {
          label: 'Alvállalkozó',
          field: 'subcontractor_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Kiállítás dátuma',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megrendelők között',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      editing: false,
      editingId: null,
      tax_typeOptions: [
        { value: 'Tételes elszámolás szerint', text: 'Tételes elszámolás szerint' },
        { value: 'Általánydíjas', text: 'Általánydíjas' },
      ],
      workOptions: [
        { label: 'betonacél szerelés', value: 'betonacél szerelés' },
        { label: 'beton szállítás', value: 'beton szállítás' },
        { label: 'betonacél szállítás', value: 'betonacél szállítás' },
        { label: 'földmunka', value: 'földmunka' },
        { label: 'komplett szerkezetépítés', value: 'komplett szerkezetépítés' },
        { label: 'kőműves munkák', value: 'kőműves munkák' },
        { label: 'szerkezetépítés', value: 'szerkezetépítés' },
        { label: 'vízszigetelés', value: 'vízszigetelés' },
        { label: 'zsalu bontás és takarítás', value: 'zsalu bontás és takarítás' },
        { label: 'gépészet', value:'gépészet' },
      ],
      fadOptions: [
        {
          text: '+ 27%',
          value: '+ 27%',
        },
        {
          text: '-% FAD',
          value: '-% FAD',
        },
      ],
      form5: {
        monolit: '',
        subcontractor: '',
        subject_of_the_contract: '',
        date_of_contract: '',
        paydesc: '',
        number: '',
        signature: '',
      },
      form4: {
        tig_number: '',
        tig_date: '',
        work_number: '',
        subject_of_the_contract: '',
        price: '',
        tax: '',
        subcontractor: '',
        paid: false,
        signature: '',
      },
      form3: {
        signature: '',
        tax: '',
        new_price: '',
        new_end_date: '',
        change_number: '',
        monolit: '',
        date_of_contract: '',
        subcontractor: '',
        subject_of_the_contract: '',
        taxType: '',
        work: '',
      },
      form2: {
        monolit: '',
        subcontractor: '',
        date_of_contract: '',
        signature: '',
      },
      form: {
        work: '',
        work_number: '',
        subject_of_the_contract: '',
        building_permit_number: '',
        tax: '',
        monolit: '',
        subcontractor: '',
        site_handover_date: '',
        committed_completion_date: '',
        payment_deadline: '',
        date_of_contract: '',
        tax_type: '',
        price: '',
        signature: '',
      },
      rows: [],
      searchTerm: '',
      project_data: [],
      contractList: [],
      paydesc_enabled: false,
    }
  },
  watch: {
    searchTerm() {
      this.$refs.vgtTable.refresh()
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getDocuments()
    this.getSubcontractor()
    this.getProjectData()
    this.getAllProjectContract()
  },
  methods: {
    changeForm3Subcontractor() {
      const contract = this.contractList.find(
        // eslint-disable-next-line eqeqeq
        contract => contract.subcontractor_id == this.form3.subcontractor,
      )

      if (contract !== undefined)
      {
        this.form3.subject_of_the_contract = contract.data.subject_of_the_contract
        this.form3.tax = contract.data.tax
        this.form3.taxType = contract.data.tax_type
        this.form3.new_price = contract.data.price
        this.form3.work = contract.data.work
        this.form3.signature = contract.data.signature

        const count = this.rows.filter(
          // eslint-disable-next-line eqeqeq
          row => row.subcontractor_id == this.form3.subcontractor && row.type == 'building_contract',
        ).length

        this.form3.change_number = count + 1
      } else {
        this.form3.change_number = 1
      }
    },
    changeForm4Subcontractor() {
      const contract = this.contractList.find(
        // eslint-disable-next-line eqeqeq
        contract => contract.subcontractor_id == this.form4.subcontractor,
      )

      if (contract !== undefined)
      {
        this.form4.subject_of_the_contract = contract.data.subject_of_the_contract
        this.form4.tax = contract.data.tax

        const count = this.rows.filter(
          // eslint-disable-next-line eqeqeq
          row => row.subcontractor_id == this.form4.subcontractor && row.type == 'certificate_of_completion',
        ).length

        this.form4.tig_number = count + 1

        this.form4.signature = contract.data.signature
      } else {
        this.form4.tig_number = 1
      }
    },
    changeForm5Subcontractor() {
      const contract = this.contractList.find(
        // eslint-disable-next-line eqeqeq
        contract => contract.subcontractor_id == this.form5.subcontractor,
      )

      if (contract !== undefined) {
        this.form5.subject_of_the_contract = contract.data.subject_of_the_contract
      }
    },
    changeContractType() {
      if (this.form.tax_type === 'Tételes elszámolás szerint') {
        this.form.price = 0
      }
    },
    edit(id) {
      this.editing = true
      this.editing_id = id
      const data = this.rows.find(row => row.id === id)
      if (data.type === 'contract') {
        this.form.work = data.data.work
        this.form.work_number = data.data.work_number
        this.form.subject_of_the_contract = data.data.subject_of_the_contract
        this.form.building_permit_number = data.data.building_permit_number
        this.form.tax = data.data.tax
        this.form.monolit = data.data.monolit
        this.form.subcontractor = data.subcontractor_id
        this.form.site_handover_date = data.data.site_handover_date
        this.form.committed_completion_date = data.data.committed_completion_date
        this.form.payment_deadline = data.data.payment_deadline
        this.form.date_of_contract = data.data.date_of_contract
        this.form.tax_type = data.data.tax_type
        this.form.price = data.data.price
        this.form.signature = data.data.signature
        this.type = 'contract'
      } else if (data.type === 'individual_tax') {
        this.form2.monolit = data.data.monolit
        this.form2.subcontractor = data.subcontractor_id
        this.form2.date_of_contract = data.data.date_of_contract
        this.form2.subject_of_the_contract = data.data.subject_of_the_contract
        this.type = 'individual_tax'
      } else if (data.type === 'building_contract') {
        this.form3.monolit = data.data.monolit
        this.form3.subcontractor = data.subcontractor_id
        this.form3.date_of_contract = data.data.date_of_contract
        this.form3.change_number = data.data.change_number
        this.form3.subject_of_the_contract = data.data.subject_of_the_contract
        this.form3.tax = data.data.tax
        this.form3.taxType = data.data.taxType
        this.form3.new_price = data.data.new_price
        this.form3.new_end_date = data.data.new_end_date
        this.form3.work = data.data.work
        this.form3.signature = data.data.signature
        this.type = 'building_contract'
      } else if (data.type === 'certificate_of_completion') {
        this.form4.monolit = data.data.monolit
        this.form4.subcontractor = data.subcontractor_id
        this.form4.date_of_contract = data.data.date_of_contract
        this.form4.tig_number = data.data.tig_number
        this.form4.tig_date = data.data.tig_date
        this.form4.price = data.data.price
        this.form4.work_number = data.data.work_number
        this.form4.subject_of_the_contract = data.data.subject_of_the_contract
        this.form4.tax = data.data.tax
        this.form4.paid = false
        this.form4.signature = data.data.signature
        this.type = 'certificate_of_completion'
      } else if (data.type === 'contract_attachment') {
        this.form5.monolit = data.data.monolit
        this.form5.subcontractor = data.subcontractor_id
        this.form5.date_of_contract = data.data.date_of_contract
        this.form5.subject_of_the_contract = data.data.subject_of_the_contract
        this.form5.paydesc = data.data.paydesc
        this.form5.number = data.data.number
        this.form5.signature = data.data.signature
        if (this.form5.paydesc != null) {
          this.paydesc_enabled = true
        } else {
          this.paydesc_enabled = false
        }
        this.type = 'contract_attachment'
      }
      this.$refs.document_generator_modal.show()
    },
    editGenerateTIG() {
      if (this.form4.tig_number == -1 && this.project_data.extra && this.project_data.extra.length > 0) {
        Swal.fire({
          title: 'Van pótmunka biztos kiszeretnéd állítani a végszámlát?',
          showDenyButton: false,
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-danger mr-1',
          },
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Tovább',
          cancelButtonText: 'Mégsem',
        }).then(result => {
          if (result.isDenied) {
            return null
          }
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'project/document/create/tig/edit',
              data: {
                form: this.form4,
                type: this.type,
                project_id: this.project_data.id,
                old_id: this.editing_id,
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
              responseType: 'blob',
            }).then(res => {
              const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
              const link = document.createElement('a')
              link.href = url

              const date = new Date()
              const year = date.getFullYear()
              const month = date.getMonth() + 1
              const day = date.getDate()
              const today = `${year}-${month}-${day}`

              const subcontractor = this.subcontractorOptions.find(
                sub => sub.id == this.form4.subcontractor,
              ).company_name

              link.setAttribute('download', `${today} Teljesítési igazolás végszámla ${subcontractor}.pdf`)
              document.body.appendChild(link)
              link.click()
              this.getDocuments()
              this.form4.tig_date = ''
              this.form4.price = ''
              this.form4.subcontractor = ''
              this.form4.tig_number = ''
              this.form4.signature = ''
              this.$refs.document_generator_modal.hide()
            }).catch(error => {
              if (error.response.status === 422) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Minden mező kitöltése kötelező',
                    icon: 'XOctagonIcon',
                    variant: 'danger',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'A teljesítési dátum csak hétköznap lehet',
                    icon: 'XOctagonIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          }
        })
      } else {
        axios({
          method: 'post',
          url: 'project/document/create/tig/edit',
          data: {
            form: this.form4,
            type: this.type,
            project_id: this.project_data.id,
            old_id: this.editing_id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()
          const today = `${year}-${month}-${day}`

          const subcontractor = this.subcontractorOptions.find(
            sub => sub.id == this.form4.subcontractor,
          ).company_name

          if (this.form4.tig_number == -1) {
            link.setAttribute('download', `${today} Teljesítési igazolás végszámla ${subcontractor}.pdf`)
          } else {
            link.setAttribute('download', `${today} Teljesítési igazolás ${this.form4.tig_number} ${subcontractor}.pdf`)
          }
          document.body.appendChild(link)
          link.click()
          this.getDocuments()
          this.form4.tig_date = ''
          this.form4.price = ''
          this.form4.subcontractor = ''
          this.form4.signature = ''
          this.$refs.document_generator_modal.hide()
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Minden mező kitöltése kötelező',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'A teljesítési dátum csak hétköznap lehet',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    generateTIG() {
      if (this.form4.tig_number == -1 && this.project_data.extra && this.project_data.extra.length > 0) {
        Swal.fire({
          title: 'Van pótmunka biztos kiszeretnéd állítani a végszámlát?',
          showDenyButton: false,
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-danger mr-1',
          },
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Tovább',
          cancelButtonText: 'Mégsem',
        }).then(result => {
          if (result.isDenied) {
            return null
          }
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'project/document/create/tig',
              data: {
                form: this.form4,
                type: this.type,
                project_id: this.project_data.id,
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
              responseType: 'blob',
            }).then(res => {
              const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
              const link = document.createElement('a')
              link.href = url

              const date = new Date()
              const year = date.getFullYear()
              const month = date.getMonth() + 1
              const day = date.getDate()
              const today = `${year}-${month}-${day}`

              const subcontractor = this.subcontractorOptions.find(
                sub => sub.id == this.form4.subcontractor,
              ).company_name

              link.setAttribute('download', `${today} Teljesítési igazolás végszámla ${subcontractor}.pdf`)
              document.body.appendChild(link)
              link.click()
              this.getDocuments()
              this.form4.tig_date = ''
              this.form4.price = ''
              this.form4.subcontractor = ''
              this.form4.tig_number = ''
              this.form4.signature = ''
              this.$refs.document_generator_modal.hide()
            }).catch(error => {
              if (error.response.status === 422) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Minden mező kitöltése kötelező',
                    icon: 'XOctagonIcon',
                    variant: 'danger',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'A teljesítési dátum csak hétköznap lehet',
                    icon: 'XOctagonIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          }
        })
      } else {
        axios({
          method: 'post',
          url: 'project/document/create/tig',
          data: {
            form: this.form4,
            type: this.type,
            project_id: this.project_data.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
          const link = document.createElement('a')
          link.href = url
          const date = new Date()
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()
          const today = `${year}-${month}-${day}`

          const subcontractor = this.subcontractorOptions.find(
            sub => sub.id == this.form4.subcontractor,
          ).company_name

          if (this.form4.tig_number == -1) {
            link.setAttribute('download', `${today} Teljesítési igazolás végszámla ${subcontractor}.pdf`)
          } else {
            link.setAttribute('download', `${today} Teljesítési igazolás ${this.form4.tig_number} ${subcontractor}.pdf`)
          }
          document.body.appendChild(link)
          link.click()
          this.getDocuments()
          this.form4.tig_date = ''
          this.form4.price = ''
          this.form4.signature = ''
          this.form4.subcontractor = ''
          this.$refs.document_generator_modal.hide()
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Minden mező kitöltése kötelező',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'A teljesítési dátum csak hétköznap lehet',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    getAllProjectContract() {
      axios({
        method: 'get',
        url: `project/${this.$route.params.id}/document/contract`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.contractList = res.data
      })
    },
    editGenerateBuildingContract() {
      axios({
        method: 'post',
        url: 'project/document/create/building-contract/edit',
        data: {
          old_id: this.editing_id,
          form: this.form3,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form3.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Alvállalkozói építési vállalkozási szerződés ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form3.new_price = ''
        this.form3.new_end_date = ''
        this.form3.change_number = ''
        this.form3.subcontractor = ''
        this.form3.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    generateBuildingContract() {
      axios({
        method: 'post',
        url: 'project/document/create/building-contract',
        data: {
          form: this.form3,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form3.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Alvállalkozói építési vállalkozási szerződés ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form3.new_price = ''
        this.form3.new_end_date = ''
        this.form3.change_number = ''
        this.form3.subcontractor = ''
        this.form3.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    editGenerateIndividualTax() {
      axios({
        method: 'post',
        url: 'project/document/create/individual-tax/edit',
        data: {
          old_id: this.editing_id,
          form: this.form2,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form2.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Kisadózó nyilatkozat ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form2.subcontractor = ''
        this.form2.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    generateIndividualTax() {
      axios({
        method: 'post',
        url: 'project/document/create/individual-tax',
        data: {
          form: this.form2,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form2.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Kisadózó nyilatkozat ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form2.subcontractor = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    editGenerateContractAttachment() {
      axios({
        method: 'post',
        url: 'project/document/create/contractAttachment/edit',
        data: {
          old_id: this.editing_id,
          form: this.form5,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form5.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Szerződés melléklet ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form5.subcontractor = ''
        this.form5.subject_of_the_contract = ''
        this.form5.date_of_contract = ''
        this.form5.paydesc = ''
        this.form5.number = ''
        this.form5.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    generateContractAttachment() {
      axios({
        method: 'post',
        url: 'project/document/create/contractAttachment',
        data: {
          form: this.form5,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form5.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Szerződés melléklet ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form5.subcontractor = ''
        this.form5.subject_of_the_contract = ''
        this.form5.date_of_contract = ''
        this.form5.paydesc = ''
        this.form5.number = ''
        this.form5.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    generateContract() {
      axios({
        method: 'post',
        url: 'project/document/create/contract',
        data: {
          form: this.form,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Szerződés ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form.subcontractor = ''
        this.form.site_handover_date = ''
        this.form.payment_deadline = ''
        this.form.tax_type = ''
        this.form.price = ''
        this.form.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    editGenerateContract() {
      axios({
        method: 'post',
        url: 'project/document/create/contract/edit',
        data: {
          old_id: this.editing_id,
          form: this.form,
          type: this.type,
          project_id: this.project_data.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const today = `${year}-${month}-${day}`

        const subcontractor = this.subcontractorOptions.find(
          sub => sub.id == this.form.subcontractor,
        ).company_name

        link.setAttribute('download', `${today} Szerződés ${subcontractor}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.getDocuments()
        this.form.subcontractor = ''
        this.form.site_handover_date = ''
        this.form.payment_deadline = ''
        this.form.tax_type = ''
        this.form.price = ''
        this.form.signature = ''
        this.$refs.document_generator_modal.hide()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    getSubcontractor() {
      axios({
        method: 'get',
        url: 'subcontractor/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.subcontractorOptions = res.data
      })
    },
    archive(id) {
      axios({
        method: 'put',
        url: `project/document/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen archiválva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    editProject(id) {
      this.$router.push({ name: 'monolit.document.edit', params: { id } })
    },
    getProjectData() {
      axios({
        method: 'get',
        url: `project/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.title = res.data.name
        this.project_data = res.data
        this.form.work_number = res.data.work_number
        this.form.building_permit_number = res.data.building_permit
        this.form.tax = res.data.fad
        if (res.data.work_number.substring(0, 2) === 'ME') {
          this.form.monolit = 'ME'
          this.form2.monolit = 'ME'
          this.form3.monolit = 'ME'
          this.form4.monolit = 'ME'
          this.form5.monolit = 'ME'
        } else {
          this.form.monolit = 'MP'
          this.form2.monolit = 'MP'
          this.form3.monolit = 'MP'
          this.form4.monolit = 'MP'
          this.form5.monolit = 'MP'
        }
        this.form.committed_completion_date = res.data.end_date
        res.data.invoice_changes.forEach(element => {
          if (element.contract_new_date !== null) {
            this.form.committed_completion_date = element.contract_new_date
          }
        })
        this.form.subject_of_the_contract = res.data.description
        this.form3.subject_of_the_contract = res.data.description
        this.form4.subject_of_the_contract = res.data.description
        this.form5.subject_of_the_contract = res.data.description
        this.form.date_of_contract = new Date().toISOString().slice(0, 10)
        this.form2.date_of_contract = new Date().toISOString().slice(0, 10)
        this.form3.date_of_contract = new Date().toISOString().slice(0, 10)
        this.form5.date_of_contract = new Date().toISOString().slice(0, 10)
        this.form4.work_number = res.data.work_number
        this.form4.tax = res.data.fad
        this.form3.tax = res.data.fad
        this.form.tax = res.data.fad
      })
    },
    getDocuments() {
      axios({
        method: 'get',
        url: `project/document/list/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
    downloadDocument(id) {
      axios({
        method: 'post',
        url: `project/document/create/contract/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        const document_data = this.rows.find(doc => doc.id === id)
        if (document_data.data.tig_number) {
          if (document_data.data.tig_number === -1) {
            link.setAttribute('download', `${document_data.data.tig_date} Teljesítési igazolás végszámla ${document_data.subcontractor_name}.pdf`)
          } else {
            link.setAttribute('download', `${document_data.data.tig_date} Teljesítési igazolás ${document_data.data.tig_number} ${document_data.subcontractor_name}.pdf`)
          }
        } else {
          link.setAttribute('download', `${document_data.data.date_of_contract} ${document_data.document_name} ${document_data.subcontractor_name}.pdf`)
        }
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
